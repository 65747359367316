import React, { useContext, useMemo } from "react";
import { SearchBox } from "react-instantsearch-dom";
import classNames from "classnames";

import { JobsContext, ModalContext, SearchContext } from "context/providers";

import { SEARCH_BY_ATTRIBUTES } from "lookup";

import ActionButtonsPanel from "components/ActionButtonsPanel";
import SearchFilters from "components/SearchFilters";
import ApplicationsTabs from "./components/ApplicationsTabs";
import SelectDropDown from "./components/SelectDropDown";
import SavedSearches from "components/SavedSearches";
import UserCardList from "components/UserCardList";

import algoliaLogo from "images/algolia-logo.png";

import "./index.css";
import { useLocation } from "react-router-dom-v5-compat";
import IdealFitFilter from "components/SearchFilters/idealFitFilters";
import clsx from "clsx";
import { TABS } from "context/constants/common";

// https://stackoverflow.com/a/65050864/2104976
// Each widget is responsibile for its own state
// We cannot set the default value for job title
// if the refinement list does not exist
// But we don't want to show the refinement list for job title
// So we are creating this "virtual" refinement list

const Applications = ({ user }) => {
  const { showModal } = useContext(ModalContext);
  const { jobOpp, jobCalendarEvents } = useContext(JobsContext);
  const { searchState, setSearchState, clearSearchState } =
    useContext(SearchContext);

  const isJobPage = useMemo(() => !!jobOpp.id, [jobOpp]);
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const currentTab = params.get("tab");

  const selectedOption = useMemo(() => {
    return searchState.searchByAttributes;
  }, [searchState.searchByAttributes]);

  const handleSelectOption = (option) => {
    const finalState = {
      ...searchState,
      searchByAttributes: option,
    };
    setSearchState(finalState);
  };

  return (
    <div
      className={classNames("w-full flex flex-column", {
        "!border-t-2": isJobPage,
      })}
    >
      <div className="w-1/4 border-r-2 pt-2">
        <div className="sticky top-0 pt-3 z-10">
          <div
            className={clsx({
              hidden: currentTab !== TABS.IDEALFIT,
            })}
          >
            <IdealFitFilter />
          </div>
          <div
            className={clsx({
              hidden: currentTab === TABS.IDEALFIT,
            })}
          >
            <div className="px-4">
              <img
                className="h-[18.86px] w-[82.8px] mb-2"
                src={algoliaLogo}
                alt="algolia logo"
              />
              <div className="flex justify-between">
                <p className="uppercase text-base text-gray-400 font-bold">
                  Search By
                </p>
                <span
                  className="mb-4 pl-2 text-sky-500 block py-0 w-min whitespace-nowrap cursor-pointer opacity-70 transition-all select-none hover:opacity-100 underline hover:no-underline"
                  onClick={clearSearchState}
                >
                  Clear Filters
                </span>
              </div>
              <div className="flex items-center w-full">
                <SearchBox
                  className="w-3/4"
                  translations={{
                    placeholder: "Search for users",
                  }}
                  autoFocus
                />
                <SelectDropDown
                  options={SEARCH_BY_ATTRIBUTES}
                  selectedOption={selectedOption}
                  action={handleSelectOption}
                />
              </div>
            </div>

            <div className="px-4 border-b-2">
              <SavedSearches />
            </div>

            <div className="overflow-y-scroll h-[72vh] lg:h-[75vh] overflow-x-hidden px-2 customScrollBar mt-2">
              <SearchFilters />
            </div>
          </div>
        </div>
      </div>

      <div className="w-[70%]">
        {isJobPage ? (
          <ApplicationsTabs />
        ) : (
          <UserCardList collectionKey="hits" pagination stats />
        )}
      </div>

      <div className="w-[5%] border-l">
        <ActionButtonsPanel
          isJobPage={isJobPage}
          user={user}
          jobCalendarEvents={jobCalendarEvents}
          showModal={showModal}
        />
      </div>
    </div>
  );
};

export default Applications;
