/**
 * Search component used in the Ideal Fit tab
 */

import { useContext, useEffect } from "react";
import { JobsContext, MatchContext } from "context/providers";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Badge } from "components/ui/badge";
import { X } from "lucide-react";
import clsx from "clsx";
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group";
import { convertMilesToMeters } from "utils/common";

// in meters
const DEFAULT_GEO_DISTANCE = convertMilesToMeters(50);

// Label in miles, actual value in meters
const GEO_DISTANCES = [10, 25, 50, 100, 250].map((d) => ({
  label: d.toString(),
  value: convertMilesToMeters(d),
}));

function SkillFilter({ skills = [], fieldName, label, formControl }) {
  if (skills.length === 0) {
    return (
      <>
        <FormLabel className="text-sm font-semibold mb-4">{label}</FormLabel>
        <p className="text-slate-300 text-sm">
          There are no {label} for this job
        </p>
      </>
    );
  }

  return (
    <>
      <FormLabel className="text-sm font-semibold mb-4">{label}</FormLabel>
      <div className="flex gap-2 flex-wrap">
        {skills.map((skill) => (
          <FormField
            key={skill.id}
            control={formControl}
            name={fieldName}
            render={({ field }) => (
              <FormItem key={skill.id}>
                <FormControl>
                  <Badge
                    className={clsx({
                      "cursor-pointer border border-blue-300 pr-1 hover:bg-sky-400 hover:text-white": true,
                      "text-white bg-sky-600": field.value?.includes(skill.id),
                      "text-[#0EA5E9] bg-white": !field.value?.includes(
                        skill.id
                      ),
                    })}
                    variant={
                      field.value?.includes(skill.id) ? undefined : "outline"
                    }
                    onClick={() => {
                      return field.value?.includes(skill.id)
                        ? field.onChange(
                            field.value.filter((value) => value !== skill.id)
                          )
                        : field.onChange([...(field.value || []), skill.id]);
                    }}
                  >
                    {skill.name}{" "}
                    {field.value?.includes(skill.id) && (
                      <X className="w-4 h-4" />
                    )}
                  </Badge>
                </FormControl>
              </FormItem>
            )}
          />
        ))}
      </div>
    </>
  );
}

export default function IdealFitFilter() {
  const { jobOpp } = useContext(JobsContext);
  const { fetchIdealCandidates } = useContext(MatchContext);

  const form = useForm({
    defaultValues: {
      requiredSkills: jobOpp?.skills?.map((s) => s.id) || [],
      optionalSkills: jobOpp?.optionalSkills?.map((s) => s.id) || [],
      geoDistance: DEFAULT_GEO_DISTANCE,
    },
  });

  const onChange = async (data) => {
    // If we don't pass skills to override for fetching ideal candidates,
    // we read the skills from the job itself
    let requiredSkillsToOverride;
    let optionalSkillsToOverride;
    let geoDistance;
    if (jobOpp?.skills?.length !== data.requiredSkills.length) {
      requiredSkillsToOverride = data.requiredSkills.map((ds) => {
        const skill = jobOpp.skills.find((js) => js.id === ds);

        return skill;
      });
    }

    if (jobOpp?.optionalSkills?.length !== data.optionalSkills.length) {
      optionalSkillsToOverride = data.optionalSkills.map((ds) => {
        const skill = jobOpp.optionalSkills.find((js) => js.id === ds);

        return skill;
      });
    }

    if (jobOpp?.location?.locationId) {
      geoDistance = data.geoDistance;
    }

    await fetchIdealCandidates(
      requiredSkillsToOverride,
      optionalSkillsToOverride,
      geoDistance
    );
  };

  // Submit the form when a value in the form changes
  // There's no explicit submit button
  useEffect(() => {
    const subscription = form.watch((values) => onChange(values));

    return () => subscription.unsubscribe();
  });

  return (
    <section className="p-4">
      <p className="uppercase text-base text-gray-400 font-bold mb-4">
        Ideal Fit Customization
      </p>
      <Form {...form}>
        <form className="flex flex-col space-y-4">
          <section className="border border-dashed space-y-4 px-4">
            <FormField
              control={form.control}
              name="requiredSkills"
              render={() => (
                <FormItem className="pt-4">
                  <div className="mb-4">
                    <FormLabel className="uppercase text-sm font-semibold mb-4 text-indigo-500">
                      Skills
                    </FormLabel>
                  </div>

                  <SkillFilter
                    skills={jobOpp?.skills || []}
                    label="Required Skills"
                    fieldName="requiredSkills"
                    formControl={form.control}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <hr className="border-b-2 w-full" />

            <FormField
              control={form.control}
              name="optionalSkills"
              render={() => (
                <FormItem className="pb-4">
                  <SkillFilter
                    skills={jobOpp?.optionalSkills || []}
                    label="Optional Skills"
                    fieldName="optionalSkills"
                    formControl={form.control}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>

          {jobOpp?.location?.locationId && (
            <FormField
              control={form.control}
              name="geoDistance"
              render={({ field }) => (
                <FormItem className="border border-dashed p-4">
                  <div className="mb-4">
                    <FormLabel className="uppercase text-sm font-semibold mb-4 text-indigo-500">
                      Proximity Radius
                    </FormLabel>
                    <FormDescription>
                      {`${jobOpp.location.cityName}, ${jobOpp.location.stateName}, ${jobOpp.location.countryName}`}
                    </FormDescription>
                  </div>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-col space-x-1 items-baseline"
                    >
                      {GEO_DISTANCES.map((gd) => (
                        <FormItem
                          className="flex items-center space-x-3 space-y-0"
                          key={gd.value}
                        >
                          <FormControl>
                            <RadioGroupItem value={gd.value} />
                          </FormControl>
                          <FormLabel className="font-semibold">
                            {gd.label} miles
                          </FormLabel>
                        </FormItem>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </form>
      </Form>
    </section>
  );
}
